import { Container, Paper, Typography, Button } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import React from 'react'
import { useParams } from 'react-router-dom'
import PageHeader from '../../elements/PageHeader'

import { useApp } from '../../../components/elements/AppContext'
import { useApis } from '../../../components/elements/ApiContext'
import { hasDoc } from './Apis'
import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'

interface ApiExplorerProps {
  classes: any
}

const ApiExplorer: React.FC<React.PropsWithChildren<ApiExplorerProps>> = (props) => {
  const { classes } = props
  let { slug } = useParams<{ slug: string }>()
  const { click } = useApp()
  const { apiBySlug } = useApis()
  const doc = apiBySlug(slug)

  const renderError = () => {
    return (
      <Container>
        <Paper className={classes.error}>
          <Typography variant="h5" gutterBottom>
            API documentation '{slug}' unavailable
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Oops! It looks like the API for tenants-management isn't available. Here's what you can do:
          </Typography>
          <Typography variant="body1" component="ul" color="text.secondary">
            <li style={{ marginBottom: 4 }}>
              <strong>Check your login status:</strong> Ensure you're logged in to access all APIs.
            </li>
            <li style={{ marginBottom: 4 }}>
              <strong>Browse our APIs:</strong> Click on the 'APIs' link in the toolbar to explore our public and
              private APIs.
            </li>
            <li style={{ marginBottom: 4 }}>
              <strong>Need help?</strong> Visit our contact page.
            </li>
          </Typography>
        </Paper>
      </Container>
    )
  }

  return (
    <React.Fragment>
      {!doc || !doc.openapi ? (
        renderError()
      ) : (
        <Container className={classes.root}>
          <PageHeader breadcrumbs={[{ title: 'APIs', path: '/apis' }, { title: 'API Explorer' }]} title={doc.title}>
            {hasDoc(doc) && <Button onClick={click('/apis/doc/' + slug)}>Documentation</Button>}
          </PageHeader>
          <Paper className={classes.paper}>
            <SwaggerUI url={doc.openapi} tryItOutEnabled={false} />
          </Paper>
        </Container>
      )}
    </React.Fragment>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: '#eee',
      padding: '0.1rem',
    },
    error: {
      padding: '30px',
    },
  })

export default withStyles(styles, { withTheme: true })(ApiExplorer)
